<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row type="flex" :gutter="[8, 32]">
				<a-col>
					<span>申请时间：</span>
					<a-range-picker
						:allowClear="false"
						@change="onDateChange"
						v-model="DateTime"
						style="width: 240px"
					/>
				</a-col>
				<a-col>
					<a-input-search
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</a-col>
				<a-col>
					<a-button :type="'primary'" @click="exportToExcel()" v-if="isGranted('hrzb_excel')">
						<a-icon type="download" />
						导出
					</a-button>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.processId"
				:scroll="{ x: 1600, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../../shared/component-base';
import moment from 'moment';
import { CustomizeReportServiceServiceProxy } from '../../../../shared/service-proxies';
import { fileDownloadService } from '../../../../shared/utils';

export default {
	name: 'hrzb',
	mixins: [AppComponentBase],
	data() {
		return {
			columns: [
				{
					title: this.l('审批单'),
					dataIndex: 'title',
					ellipsis: true,
					sorter: false,
					align: 'center',
					width: 200,
					scopedSlots: { customRender: 'title' },
				},
				{
					title: this.l('申请时间'),
					dataIndex: 'creationTime',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'creationTime' },
					width: 150,
				},
				{
					title: this.l('申请人部门'),
					dataIndex: 'depname',
					ellipsis: true,
					sorter: false,
					align: 'center',
					width: 200,
					scopedSlots: { customRender: 'depname' },
				},
				{
					title: this.l('申请人'),
					dataIndex: 'createUserName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'createUserName' },
				},
				{
					title: this.l('审批状态'),
					dataIndex: 'isFinish',
					ellipsis: true,
					sorter: false,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'isFinish' },
				},
				{
					title: this.l('审批过程'),
					dataIndex: 'proStatues',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'proStatues' },
					width: 150,
				},
				{
					title: this.l('审核人'),
					dataIndex: 'auditName',
					ellipsis: true,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'auditName' },
				},
				{
					title: this.l('审核结果'),
					dataIndex: 'operationName',
					ellipsis: true,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'operationName' },
				},
				{
					title: this.l('增补职位'),
					dataIndex: 'zbzwname',
					ellipsis: true,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'zbzwname' },
				},
				{
					title: this.l('增补人数'),
					dataIndex: 'zbrs',
					ellipsis: true,
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'zbrs' },
				},
				{
					title: this.l('需求到岗时间'),
					dataIndex: 'dgsj',
					ellipsis: true,
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'dgsj' },
				},
				{
					title: this.l('编制人数'),
					dataIndex: 'bzrs',
					ellipsis: true,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'bzrs' },
				},
				{
					title: this.l('现有人数'),
					dataIndex: 'xyrs',
					ellipsis: true,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'xyrs' },
				},
				{
					title: this.l('增补原因'),
					dataIndex: 'zbyy',
					ellipsis: true,
					align: 'center',
					width: 200,
					scopedSlots: { customRender: 'zbyy' },
				},
			],
			tableData: [],

			DateTime: [],
			StartTime: null,
			EndTime: null,

			_fileDownloadService: '',
		};
	},

	created() {
		this._CustomizeReportServiceServiceProxy = new CustomizeReportServiceServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
	},

	mounted() {
		this.getCycle();
		this.getData();
	},

	methods: {
		getData() {
			this.loading = true;
			this._CustomizeReportServiceServiceProxy
				.getHrzbList(
					this.StartTime ? moment(this.StartTime) : undefined,
					this.EndTime ? moment(this.EndTime) : undefined,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
					this.tableData.map((item) => {
						item.creationTime = item.creationTime
							? moment(item.creationTime).format('YYYY-MM-DD  HH:mm')
							: ' ';
						item.dgsj = item.dgsj ? moment(item.dgsj).format('YYYY-MM-DD') : ' ';
					});
				});
		},

		/**
		 * 导出为excel
		 */
		exportToExcel() {
			this.loading = true;
			this._CustomizeReportServiceServiceProxy
				.getHrzbListToExcel(
					this.StartTime ? moment(this.StartTime) : undefined,
					this.EndTime ? moment(this.EndTime) : undefined,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((result) => {
					this._fileDownloadService.downloadTempFile(result);
				});
		},

		getCycle() {
			var now = new Date(); //当前日期
			var nowMonth = now.getMonth(); //当前月
			var nowYear = now.getFullYear(); //当前年
			this.StartTime = moment(new Date(nowYear, nowMonth, 1)).format('YYYY-MM-DD');
			this.EndTime = moment(new Date(nowYear, nowMonth + 1, 0)).format('YYYY-MM-DD');
			this.DateTime = [this.StartTime, this.EndTime];
		},

		onDateChange(e) {
			if (e.length > 0) {
				this.StartTime = e[0];
				this.EndTime = e[1];
			}
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},
	},
};
</script>

<style scoped></style>
